<script>
import { mapActions, mapGetters } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'
export default {
  name: 'ManagementCertificates',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    Tabs: () => import('@/components/general/Tabs'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          name: 'asc'
        },

        page: 1,
        limit: 12,
        lastPage: 1
      },

      modalRemoveOpen: false,
      sessionId: null,
      totalLinkedCountModal: null
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ]),
    certificates () {
      return this.$store.getters.certificates || []
    },

    canReadCertificates () {
      return [
        'read',
        'write'
      ].indexOf(this.getContextPermission('certificates')) !== -1
    },

    canWrite () {
      return this.getContextPermission('certificates') === 'write'
    },

    modalDescription () {
      if (this.totalLinkedCountModal > 0) {
        if (this.isProgramEnabled) {
          return 'Tem certeza que deseja inativar esse certificado? Todos os ' + this.totalLinkedCountModal + ' vínculos serão removidos das soluções, trilhas e programas que possuírem esse certificado'
        } else {
          return 'Tem certeza que deseja inativar esse certificado? Todos os ' + this.totalLinkedCountModal + ' vínculos serão removidos das soluções e trilhas que possuírem esse certificado'
        }
      } else {
        return 'Tem certeza que deseja inativar esse certificado?'
      }
    },

    isProgramEnabled () {
      return this.activeModules.includes('program')
    }
  },

  watch: {
    'pagination.page' () {
      this.fetchData()
    }
  },

  created () {
    if (this.$route.name !== 'management.certificates') {
      return
    }

    this.fetchData()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptListCertificates',
      'attemptToggleCertificateActive'
    ]),

    createNewCertificate () {
      this.$router.push({ name: 'management.certificates.create' })
    },

    edit (id) {
      this.$router.push({
        name: 'management.certificates.edit',
        params: { id: id }
      })
    },

    toggleActivate (isActivation) {
      this.setFetching(true)

      this.attemptToggleCertificateActive(this.sessionId)
        .then(() => {
          if (isActivation) {
            this.setFeedback({ message: this.$t('management.certificate:feedback.toggle.active') })
          } else {
            this.setFeedback({ message: this.$t('management.certificate:feedback.toggle.inactive') })
          }

          this.modalRemoveOpen = false

          this.pagination.page = 1
          this.pagination.limit = 12
          this.fetchData()
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    searchCertificate (search) {
      this.pagination.query.name = search
      this.getCertificatesList()
    },

    getCertificatesList () {
      this.fetchData()
    },

    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }

      const date = new Date(Date.parse(datetime))

      return Intl.DateTimeFormat('pt-BR').format(date)
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      if (this.pagination.page < this.pagination.lastPage) {
        this.pagination.page += 1
      }
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    fetchData () {
      this.setFetching(true)

      this.attemptListCertificates(this.pagination)
        .then((data) => {
          this.pagination.page = data.actualPage
          this.pagination.lastPage = data.lastPage
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    viewCertificateRelations (id) {
      this.$router.push({
        name: 'management.certificates.list.relations',
        params: { id }
      })
    },

    confirmRemove (item) {
      if (item.active) {
        this.sessionId = item.id
        this.totalLinkedCountModal = item.totalLinked
        this.modalRemoveOpen = true
      } else {
        this.toggle()
      }
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>
    <FilterList>
      <Action
        v-if="canWrite"
        slot="button"
        type="button"
        primary
        large
        fixed-width
        :text="$t('management.certificate:btn.add')"
        @click="createNewCertificate"
      />
      <!--
 TODO: FilterOrder
-->
      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchCertificate"
      />
    </FilterList>
    <div
      v-if="certificates.length > 0"
      class="center management-content-construction"
    >
      <Datatable :items="certificates">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('management.certificate.list:datatable.header.1') }}
            </th>
            <th class="th">
              {{ $t('management.certificate.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('management.certificate.list:datatable.header.3') }}
            </th>
            <th class="th">
              {{ $t('management.certificate.list:datatable.header.4') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">{{ props.item.name }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.totalLinked }}</span>
            </td>
            <td class="td">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
            <td
              v-if="canWrite || canReadCertificates"
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  v-if="(props.item.totalLinked > 0) && canReadCertificates"
                  :text="$t('management.certificate:relations.list.view')"
                  @click="viewCertificateRelations(props.item.id)"
                />
                <DropdownLink
                  v-if="canWrite"
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  v-if="props.item.active && canWrite"
                  :text="$t('global:deactivate')"
                  @click="confirmRemove(props.item)"
                />
                <DropdownLink
                  v-else-if="!props.item.active && canWrite"
                  :text="$t('global:activate')"
                  @click="toggleActivate(!props.item.active)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
      <RouterView />
      <ModalConfirm
        :active="modalRemoveOpen"
        :title="$t('management.certificate:deactivate.title')"
        :description="$t(modalDescription)"
        @confirmAction="toggleActivate"
        @cancelAction="cancelRemove"
      />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
